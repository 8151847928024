import { SET_ERROR, CLEAR_ERROR } from '../actions/actionsError';


const defaultState = null;

export default function reducerError(state = defaultState, { type, error }) {

    switch (type) {
        case SET_ERROR:
            return error;
        case CLEAR_ERROR:
            return null;
        default:
            return state;
    }
}