import React from "react";
import { Grid } from "@material-ui/core";
import Cantidades from "./cantidades/index";
import Filtros from "./filtros/index";
import Lista from "./lista/index";

function Page(props) {
  let {
    inspecciones: Inspecciones,
    inspecciones_filter: InspeccionesFilter,
    set_inspecciones_filter: SetInspeccionesFilter,
    is_loading: IsLoading,
  } = props;

  return (
    <div style={{ marginTop: "80px" }}>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Cantidades inspecciones_filter={InspeccionesFilter} is_loading={IsLoading} />
        </Grid>
        <Grid item xs={7}>
          <Filtros inspecciones={Inspecciones} set_inspecciones_filter={SetInspeccionesFilter} is_loading={IsLoading} />
        </Grid>
        <Grid item xs={12}>
          <Lista inspecciones_filter={InspeccionesFilter} is_loading={IsLoading} />
        </Grid>
      </Grid>
    </div>
  );
}

export default Page;