import React, { useEffect } from 'react';
import { Route, Switch, useHistory, Redirect } from 'react-router-dom';
import Error401 from './containers/401';
import AppBar from './containers/appBar';
import Empty from './containers/empty';

import LoadPage from './containers/loadPage';
import { auth } from './services/firebase';
import { getCurrentUser } from './services/auth.js';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import * as ROUTES from './constants/routes';
import * as PERMISSIONS from './constants/permissions';
import { accionOpenSelectorContrato } from './containers/selectorContrato/actions';
import { initLogin, finishLogin } from './redux/actions/actionsLogin';
import { startLoading, stopLoading } from './redux/actions/actionsLoading';
import { setContratoProyecto, setUsuario } from './redux/actions/actionsSesion';
import clsx from 'clsx';
import LoginPage from './containers/loginPage';
import SelectorContratos from './containers/selectorContrato';
import { getProyecto } from './services/database/proyecto';
import { getContrato } from './services/database/contrato';
import { getUsuario } from './services/database/usuario';
import DrawerMain from './containers/drawerMain';
import Dashboard from './containers/dashboard';
import Page_1 from './containers/page_1';
import { Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    hide: {
        display: 'none',
    },
    // main: {
    //     display: 'flex',
    //     width: "100%"
    // },
    root: {
        // display: 'flex',
        marginLeft: '35px'
    },
    content: {
        display: 'flex',
        flexGrow: 1,
        padding: theme.spacing(11, 1, 3, 1),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),

    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    }
}));

export default function App(props) {

    const classes = useStyles();

    const history = useHistory();
    const { sesion, loadingApp, errorApp, login } = useSelector(state => ({
        sesion: state.sesion,
        loadingApp: state.loading,
        errorApp: state.error,
        login: state.login
    }));
    const dispatch = useDispatch();

    const getQueryVariable = (variable) => {
        const query = window.location.search.substring(1);
        const vars = query.split("&");
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split("=");
            if (pair[0] === variable) { return pair[1]; }
        }
        return (false);
    }


    useEffect(() => {
        console.log("render dispatch")
        auth.onAuthStateChanged(async user => {
            try {
                if (user) {
                    let contrato = getQueryVariable("contrato");
                    let proyecto = getQueryVariable("proyecto");

                    if (contrato && proyecto) {
                        localStorage.setItem("contrato", contrato);
                        localStorage.setItem("proyecto", proyecto);
                        history.push("/");
                    }
                    const usuario = await getUsuario(user.uid);
                    if (usuario) {
                        localStorage.setItem("contrato", usuario.contratos_visibles_ref[0]);
                        localStorage.setItem("proyecto", usuario.proyectos_visibles_ref[0]);
                        let contratoObj = await getContrato(usuario.contratos_visibles_ref[0]);
                        let proyectoObj = await getProyecto(usuario.proyectos_visibles_ref[0]);
                        dispatch(setContratoProyecto(contratoObj, proyectoObj));
                    }
                    const tokens = await getCurrentUser();
                    dispatch(setUsuario({
                        ref: usuario._id,
                        photoUrl: user.photoURL,
                        nombre: user.displayName,
                        email: user.displayName,
                        permisos: usuario.permisos,
                        aplicaciones: {
                            logCartas: true,
                            logIncidentes: true,
                            logCalidad: true,
                            logSolicitudesInformacion: true,
                            logInspecciones: true,
                            gestorInformacion: true,
                            bitacora: true
                        },
                        rol: (tokens && tokens.claims && tokens.claims.rol) ? tokens.claims.rol.rol : ""
                    }));
                    // setLogin(false);
                    dispatch(finishLogin());
                    loadContrato()
                        .then(() => {
                            // setLoading(false);
                            // setOpenSelectorContrato(false);
                            dispatch(stopLoading());
                        })
                        .catch((error) => {
                            console.log(error);
                            // setLoading(true);
                            // setOpenSelectorContrato(true);
                            dispatch(startLoading());
                            dispatch(accionOpenSelectorContrato());
                        });

                } else {
                    dispatch(initLogin());
                    // setLogin(true);
                }
            } catch (error) {
                console.log(error);
                dispatch(initLogin());
                // setLogin(true);
            }
        });
    }, []);

    const loadContrato = async () => {
        const contrato = localStorage.getItem("contrato");
        const proyecto = localStorage.getItem("proyecto");
        if (!contrato || !proyecto || contrato === 'undefined' || proyecto === 'undefined') {
            throw new Error("Usuario sin contrato o proyecto");
        } else {
            const responses = await Promise.all([
                getContrato(contrato),
                getProyecto(proyecto)
            ]);
            if (!responses[0] || !responses[1]) {
                throw new Error("Contrato o proyecto no existen");
            } else {
                dispatch(setContratoProyecto(responses[0], responses[1]));
            }
        }
        return;
    }

    const privateRoute = ({ component: Component, permission, path, ...rest }) => {
        return (
            <Route
                path={path}
                {...rest}
                render={(props) => {
                    if (errorApp) return (<Redirect to='/accessError' />);
                    if (!errorApp && path === ROUTES.ACCESS_ERROR) return (<Redirect to='/' />);
                    if (login) return (<Redirect to='/login' />);
                    if ((permission && sesion.usuario && !sesion.usuario.permisos[permission])) {
                        return (<Redirect to='/' />);
                    }
                    return (<Component {...props} />);
                }}
            />

        );
    }
    const mainApp = (
        <Container maxWidth="xl"  >
            <div className={clsx({ [classes.hide]: loadingApp, [classes.root]: !loadingApp })}>

                {(!login && !errorApp) &&
                    <AppBar nombreModulo={sesion.contrato ? "Inspecciones " + sesion.contrato.nombre : "Inspecciones"} />
                }
                {(!login && !errorApp) &&
                    <DrawerMain />
                }
                {/* <DrawerMain /> */}
                <Switch>
                    <Route exact={true} path={ROUTES.LOGIN} component={() => <LoginPage login={login} />} />
                    {privateRoute({
                        component: Error401,
                        path: ROUTES.ACCESS_ERROR,
                        exact: true
                    })}
                    {privateRoute({
                        component: Page_1,
                        path: ROUTES.LANDING,
                        permission: PERMISSIONS.VER_INSPECCION,
                        exact: true
                    })}
                    {/* {privateRoute({
                        component: Dashboard,
                        path: ROUTES.DASHBOARD,
                        permission: PERMISSIONS.VER_DASHBOARD_INSPECCION,
                        exact: true
                    })} */}
                    {privateRoute({
                        component: Page_1,
                        path: ROUTES.PAGE_1,
                        permission: PERMISSIONS.VER_DASHBOARD_INSPECCION,
                        exact: true
                    })}

                </Switch>


            </div>
            {(!login && !errorApp) &&
                <SelectorContratos
                />
            }
        </Container>

    );

    return (mainApp);
}
