import React from "react";
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { Grid } from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from "moment";
import "moment/locale/es";
moment().locale("es");

/**
 * Functional Component selector de fechas.
 */
function FiltroFecha({ titulo: Titulo, fecha: Fecha, set_fecha: SetFecha, is_loading: IsLoading, }) {
  return (
    <Grid container className="m-1">
      <Grid item xs={10}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker label={Titulo} value={Fecha} onChange={(e) => SetFecha(e)} format="DD/MM/YYYY" fullWidth={true} />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={2}>
        <button onClick={() => SetFecha(null)} className="btn m-0 p-0 mt-3">
          <Icon path={mdiClose} size={1} />
        </button>
      </Grid>
    </Grid>
  );
}

export default FiltroFecha;