import Axios from "axios";
const base = 'https://dev-api-proyecto.cydocs.cl'
/**
 * Request para obtener la información de un proyecto
 * @id id del proyecto
 */

export const getProyecto = async (id) => {
    return new Promise((resolve, reject) => {
        Axios.get(`${base}/proyectos/${id}`, {
        })
            .then((res) => {
                if (res.data) resolve(res.data);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para obtener la información de todos las cartas de un proyecto
 * * @id id del proyecto
 */

export const getCartasProyecto = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/proyectos/${id}/cartas`, {
        })
            .then((res) => {
                if (res.data && res.data.cartas_ref) resolve(res.data.cartas_ref);
                else resolve(null);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para agregar un conjunto de cartas a un proyecto
 * * @id id del proyecto
 * * @data conjunto de cartas
 */

export const agregarCartasProyecto = async (id, data) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.post(`${base}/proyectos/${id}/cartas`, data)
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 * Request para quitar la información de un conjunto de cartas de un proyecto
 * * @id id del proyecto
 * * @data conjunto de cartas
 */

export const quitarCartasProyecto = async (id, data) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.delete(`${base}/proyectos/${id}/cartas`, { data: data })
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}
