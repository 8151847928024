import React, { useEffect, useState } from 'react';
import Page from './page';
import { useSelector } from 'react-redux';

export default function DrawerMain(props) {
    const [openClose, setOpenClose] = useState(false);

    const { openMenuLateral, usuario } = useSelector(state => ({
        openMenuLateral: state.openMenuLateral,
        usuario: state.sesion.usuario
    }));

    useEffect(() => {
        setOpenClose(openMenuLateral);
    }, [openMenuLateral]);

    return (
        <Page
            rol={usuario ? usuario.rol : ""}
            openClose={openClose}
        />
    );
}