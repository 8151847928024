import React from "react";
import MaterialTableComponent from "../../../components/MaterialTableComponent";
import { Paper, Chip, Avatar } from "@material-ui/core";
import Icon from '@mdi/react';
import { mdiCircle, mdiFile, mdiEmail, mdiFileHidden, mdiEmailSync } from '@mdi/js';
import { red, green, yellow } from "@material-ui/core/colors/";


function Page(props) {
  let {
    inspecciones_filter: InspeccionesFilter,
    table_headers: TableHeaders,
    actions: Actions,
    components: Components,
    is_loading: IsLoading,
  } = props;

  return (
    <Paper elevation={10}>
      <div>
        <Chip label="Informe Disponible" avatar={<Icon path={mdiFile} size={1} color="gray" />} className="m-2" />
        <Chip label="Informe No Disponible" avatar={<Icon path={mdiFileHidden} size={1} color="gray" />} className="m-2" />
        <Chip label="Email Enviado" avatar={<Icon path={mdiEmail} size={1} color="gray" />} className="m-2" />
        <Chip label="Email No Enviado" avatar={<Icon path={mdiEmailSync} size={1} color="gray" />} className="m-2" />
        <Chip label="Más de 5 días para la Fecha de Cierre" avatar={<Icon path={mdiCircle} size={1} color={green[500]} />} className="m-2" />
        <Chip label="Entre 5 y 2 días para la Fecha de Cierre" avatar={<Icon path={mdiCircle} size={1} color={yellow[800]} />} className="m-2" />
        <Chip label="Menos de 2 días para la Fecha de Cierre" avatar={<Icon path={mdiCircle} size={1} color={red[500]} />} className="m-2" />
      </div>
      <MaterialTableComponent
        title="Inspecciones"
        is_loading={IsLoading}
        columns={TableHeaders}
        actions={Actions}
        data={InspeccionesFilter}
      />
    </Paper>
  );
}

export default Page;