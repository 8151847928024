import React from "react";
import { Paper, Grid } from "@material-ui/core";
import FiltroSeleccion from "../../../components/FiltroSeleccion";
import FiltroFecha from "../../../components/FiltroFecha";
import FiltroSeleccionBusqueda from "../../../components/FiltroSeleccionBusqueda";

function Page(props) {
  let {
    is_loading: IsLoading,
    proyectos: Proyectos,
    proyecto: Proyecto,
    // set_proyecto: SetProyecto,
    areas: Areas,
    area: Area,
    set_area: SetArea,
    superintendencias: Superintendencias,
    superintendencia: Superintendencia,
    set_superintendencia: SetSuperintendencia,
    disciplinas: Disciplinas,
    disciplina: Disciplina,
    set_disciplina: SetDisciplina,
    tipos_inspeccion: TiposInspeccion,
    tipo_inspeccion: TipoInspeccion,
    set_tipo_inspeccion: SetTipoInspeccion,
    emisores: Emisores,
    emisor: Emisor,
    set_emisor: SetEmisor,
    contratistas: Contratistas,
    contratista: Contratista,
    set_contratista: SetContratista,
    estados: Estados,
    estado: Estado,
    set_estado: SetEstado,
    prioridades: Prioridades,
    prioridad: Prioridad,
    set_prioridad: SetPrioridad,
    fecha_comp_cierre_desde: FechaComprometidaCierreDesde,
    set_fecha_comp_cierre_desde: SetFechaComprometidaCierreDesde,
    fecha_comp_cierre_hasta: FechaComprometidaCierreHasta,
    set_fecha_comp_cierre_hasta: SetFechaComprometidaCierreHasta,
    fecha_real_cierre_desde: FechaRealCierreDesde,
    set_fecha_real_cierre_desde: SetFechaRealCierreDesde,
    fecha_real_cierre_hasta: FechaRealCierreHasta,
    set_fecha_real_cierre_hasta: SetFechaRealCierreHasta,
  } = props;

  return (
    <Paper elevation={10} style={{ height: "168px" }}>
      <Grid container>
        {/* <Grid item xs={4}>
          <FiltroSeleccion id="filtro_contratistas" is_loading={IsLoading} opcion={Contratista} opciones={Contratistas} set_opcion={SetContratista} titulo="Contratista" />
        </Grid> */}
        <Grid item xs={4}>
          <FiltroSeleccion id="filtro_disciplinas" is_loading={IsLoading} opcion={Disciplina} opciones={Disciplinas} set_opcion={SetDisciplina} titulo="Disciplina" />
        </Grid>
        <Grid item xs={4}>
          <FiltroSeleccion id="filtro_emisores" is_loading={IsLoading} opcion={Emisor} opciones={Emisores} set_opcion={SetEmisor} titulo="Emisor" />
        </Grid>
        {/* <Grid item xs={4}>
          <FiltroSeleccion id="filtro_estados" is_loading={IsLoading} opcion={Estado} opciones={Estados} set_opcion={SetEstado} titulo="Estado" />
        </Grid> */}
        <Grid item xs={4}>
          <FiltroSeleccion id="filtro_prioridades" is_loading={IsLoading} opcion={Prioridad} opciones={Prioridades} set_opcion={SetPrioridad} titulo="Prioridad" />
        </Grid>
        <Grid item xs={4}>
          <FiltroFecha fecha={FechaComprometidaCierreDesde} is_loading={IsLoading} set_fecha={SetFechaComprometidaCierreDesde} titulo="Fecha Comp. Cierre Desde" />
        </Grid>
        <Grid item xs={4}>
          <FiltroFecha fecha={FechaComprometidaCierreHasta} is_loading={IsLoading} set_fecha={SetFechaComprometidaCierreHasta} titulo="Fecha Comp. Cierre Hasta" />
        </Grid>
        <Grid item xs={4}>
          <FiltroFecha fecha={FechaRealCierreDesde} is_loading={IsLoading} set_fecha={SetFechaRealCierreDesde} titulo="Fecha Real Cierre Desde" />
        </Grid>
        <Grid item xs={4}>
          <FiltroFecha fecha={FechaRealCierreHasta} is_loading={IsLoading} set_fecha={SetFechaRealCierreHasta} titulo="Fecha Real Cierre Hasta" />
        </Grid>
        {/* <Grid item xs={4}>
          <FiltroSeleccion id="filtro_proyectos" is_loading={IsLoading} opcion={Proyecto} opciones={Proyectos} set_opcion={SetProyecto} titulo="Proyecto" />
        </Grid> */}
        <Grid item xs={4}>
          <FiltroSeleccion id="filtro_superintendencias" is_loading={IsLoading} opcion={Superintendencia} opciones={Superintendencias} set_opcion={SetSuperintendencia} titulo="Superintendencia" />
        </Grid>
        <Grid item xs={4}>
          <FiltroSeleccion id="filtro_tipos_inspeccion" is_loading={IsLoading} opcion={TipoInspeccion} opciones={TiposInspeccion} set_opcion={SetTipoInspeccion} titulo="Tipo Inspección" />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Page;