
import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';

import Dashboard from '@material-ui/icons/Dashboard';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import './style.css';
import {
  ExpandLess,
  ExpandMore,
  ListAlt,
  Assessment,
  FolderOpen,
  ViewList,
  AccessTime as AccessTimeIcon
} from '@material-ui/icons/';

import {
  Collapse,
} from '@material-ui/core/';

import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

import { redirecTo } from '../../services/auth';
import * as ROUTES from "../../constants/routes";




const drawerWidth = 280;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },

  menuButton: {
    marginRight: 26,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    marginTop: 60,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    // width: theme.spacing(0) + 1,
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {

    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));


function Page(props) {
  const {
    openClose
  } = props;

  const classes = useStyles();
  const [openCollapse, setOpenCollapse] = useState('');
  const contrato = localStorage.getItem("contrato");
  const proyecto = localStorage.getItem("proyecto");

  return (
    <Fragment>
      {/* <div className={classes.paddinTop} onMouseEnter={() => handleDrawerOpen()}
        onMouseLeave={() => handleDrawerClose()}> */}
      <div id="MainDraw"
        className={classes.list}
        role="presentation"
      >
        <Drawer
          onClick={props.handlerClickOut}
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: openClose,
            [classes.drawerClose]: !openClose,
          })}

          classes={{
            paper: clsx({
              [classes.drawerOpen]: openClose,
              [classes.drawerClose]: !openClose,
            }),
          }}
        >
          <div className={classes.drawerHeader}>
          </div>
          <Divider />
          <List>
            {/* <Tooltip title="Dashboard" disableHoverListener={openClose} TransitionComponent={Zoom} placement="right">
              <ListItem button component={Link} to={ROUTES.DASHBOARD}  >
                <ListItemIcon >
                  <Dashboard color="secondary" />
                </ListItemIcon>
                {openClose && <ListItemText primary="Dashboard" />}
              </ListItem>
            </Tooltip> */}
            <Tooltip title="Inspecciones" disableHoverListener={openClose} TransitionComponent={Zoom} placement="right">
              <ListItem button component={Link} to={ROUTES.PAGE_1}  >
                <ListItemIcon >
                  <ViewList color="secondary" />
                </ListItemIcon>
                {openClose && <ListItemText primary="Logs" />}
              </ListItem>
            </Tooltip>

            < Divider />

            {openClose && <ListItem>
              <Typography variant="subtitle2" >
                CyDocs
                </Typography>
            </ListItem>
            }
            <Tooltip title="Gestor de Proyecto" disableHoverListener={openClose} TransitionComponent={Zoom} placement="right">
              <ListItem button className={classes.nested} onClick={() => { redirecTo("https://proyectos.cydocs.cl/", contrato, proyecto) }}>
                <ListItemIcon >
                  <Assessment color="secondary" />
                </ListItemIcon>
                {openClose && <ListItemText primary="Gestor de Proyecto" />}
              </ListItem>
            </Tooltip>
            {openClose && <Tooltip title="Logs" disableHoverListener={openClose} TransitionComponent={Zoom} placement="right">
              <ListItem button onClick={() => (openCollapse === "logs") ? setOpenCollapse("") : setOpenCollapse("logs")}>
                <ListItemIcon>
                  <ListAlt></ListAlt>
                </ListItemIcon>
                {<ListItemText primary="Logs" />}
                {(openCollapse === "logs") ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            </Tooltip>}
            {openClose && <Collapse in={(openCollapse === "logs")} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className={classes.nested} onClick={() => { redirecTo("https://cartas.cydocs.cl/", contrato, proyecto) }}>
                  <ListItemText inset secondary="Cartas" />
                </ListItem>
                <ListItem button className={classes.nested} onClick={() => { redirecTo("https://logincidentes.cydocs.cl/", contrato, proyecto) }}>
                  <ListItemText inset secondary="Incidentes" />
                </ListItem>
                <ListItem button className={classes.nested} onClick={() => { redirecTo("https://logcalidad.cydocs.cl/", contrato, proyecto) }}>
                  <ListItemText inset secondary="Calidad" />
                </ListItem>
                <ListItem button className={classes.nested} onClick={() => { redirecTo("https://logsdi.cydocs.cl/", contrato, proyecto) }}>
                  <ListItemText inset secondary="Solicitudes de Información" />
                </ListItem>
                <ListItem button className={classes.nested} onClick={() => { redirecTo("https://app-mlp.cydocs.cl/", contrato, proyecto) }}>
                  <ListItemText inset secondary="Log App Inspección" />
                </ListItem>
              </List>
            </Collapse>}
            <Tooltip title="Gestor de Información" disableHoverListener={openClose} TransitionComponent={Zoom} placement="right">
              <ListItem button className={classes.nested} onClick={() => { redirecTo("https://sgi.cydocs.cl/") }}>
                <ListItemIcon >
                  <FolderOpen color="secondary" />
                </ListItemIcon>
                {openClose && <ListItemText primary="Gestor de Información " />}
              </ListItem>
            </Tooltip>
            <Tooltip title="Asignación de Tareas" disableHoverListener={openClose} TransitionComponent={Zoom} placement="right">
              <ListItem button className={classes.nested} onClick={() => { redirecTo("http://cyd.legav.cl/proyecto/") }}>
                <ListItemIcon >
                  <PlaylistAddCheckIcon color="secondary" />
                </ListItemIcon>
                {openClose && <ListItemText primary="Asignación de Tareas" />}
              </ListItem>
            </Tooltip>
            <Tooltip title="Bitácora" disableHoverListener={openClose} TransitionComponent={Zoom} placement="right">
              <ListItem button className={classes.nested} onClick={() => { redirecTo("https://bitacora.cydocs.cl/", contrato, proyecto) }}>
                <ListItemIcon >
                  <AccessTimeIcon color="secondary" />
                </ListItemIcon>
                {openClose && <ListItemText primary="Bitácora" />}
              </ListItem>
            </Tooltip>
          </List>

        </Drawer>
      </div>
      {/* </div> */}
    </Fragment>
  );
}

export default Page;