import { START_LOADING, STOP_LOADING } from '../actions/actionsLoading';


const defaultState = true;

export default function reducerLoading(state = defaultState, { type }) {

    switch (type) {
        case START_LOADING:
            return true;
        case STOP_LOADING:
            return false;
        default:
            return state;
    }
}