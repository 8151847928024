import React from "react";
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { FormControl, InputLabel, Select, MenuItem, Grid } from "@material-ui/core";

/**
 * Functional Component selector de opciones.
 */
const FiltroSeleccion = ({ id: Identifier, titulo: Titulo, opciones: Opciones, opcion: Opcion, set_opcion: SetOpcion, is_loading: IsLoading, }) => {
  return (
    <Grid container className="m-1">
      <Grid item xs={10}>
        <FormControl fullWidth={true}>
          <InputLabel id={"label_" + Identifier}>{Titulo}</InputLabel>
          <Select labelId={"label_" + Identifier} id={Identifier} value={Opcion} onChange={(e) => SetOpcion(e.target.value)}>
            {
              Array.from(Opciones).map((o, i) => {
                return <MenuItem key={i} value={o.value}>{o.display}</MenuItem>
              })
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <button onClick={() => SetOpcion("")} className="btn m-0 p-0 mt-3">
          <Icon path={mdiClose} size={1} />
        </button>
      </Grid>
    </Grid>
  );
}

export default FiltroSeleccion;