import React, { Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import MaterialTable, { MTableAction, MTableBody } from 'material-table';


import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Moment from 'react-moment';


import IconButton from '@material-ui/core/IconButton';
import FileIcon from '@material-ui/icons/PictureAsPdf';
import FileCierreIcon from '@material-ui/icons/Description';
import RefreshIcon from '@material-ui/icons/Refresh'
import Email from '@material-ui/icons/Email';

import Grid from '@material-ui/core/Grid';

const { Parser } = require('json2csv');

const style = makeStyles(theme => ({
    toolbar: theme.mixins.toolbar,
    content: {
        flex: 1,
        padding: theme.spacing(3),

    },
    contenedor: {
        display: 'flex'
    },
    elemento: {
        width: '25%'
    },
    elemento10: {
        width: '10%'
    },
    padding16: {
        paddingLeft: '16',
        paddingRight: '16'
    },
    elementoContato: {
        width: '45%'
    },
    chip: {
        margin: theme.spacing(1),
    },
    main: {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(1),

    }
}))
//
function generarFechaView(fecha_termino) {


    var fechaView = new Date(fecha_termino.creado_el);

    if (fechaView.getFullYear() === 2100) {
        return "indefinido";
    } else {
        return <Moment format="DD/MM/YYYY">{fechaView}</Moment>
    }
    // if(fechaView.getFullYear)

}

function generarFechaCierreView(fecha_termino) {


    var fechaView = new Date(fecha_termino.fecha_real_cierre);

    if (!fecha_termino.esta_cerrada) {
        return "Por Definir";
    } else {
        return <Moment format="DD/MM/YYYY">{fechaView}</Moment>
    }
    // if(fechaView.getFullYear)

}

function generarFechaComprometidaView(rowdata){
    
    var fechaView = new Date(rowdata.fecha_comprometida_cierre);

    if (rowdata.esta_cerrada) {
        return "--";
    } else {
        return <Moment format="DD/MM/YYYY">{fechaView}</Moment>
    }
}

function generarEstadoCerrado(fila) {
    if (fila.esta_cerrada) {
        return (
            <div style={{ display: 'flex', flexFlow: 'row ', alignItems: 'center' }}>
                SI
            </div>
        )
    } else {
        return (
            <div style={{ display: 'flex', flexFlow: 'row ', alignItems: 'center' }}>
                NO
            </div>
        )
    }

}



function TablaLog(props) {
    const classes = style();
    const {
        data,
        permiso
        // rol
    } = props;

    var tituloTabla = "Listado de Inspecciones Realizadas";
    return (
        <Fragment>
            <CssBaseline />
            {/* <Drawer ></Drawer> */}

            <main>
                <Container maxWidth="xl" className={classes.main} >

                    <Grid container spacing={3}>
                        <Grid item xs={12} xl={12}>
                            <MaterialTable
                                title={tituloTabla}
                                options={{
                                    filtering: true,
                                    pageSizeOptions: [20, 30, 40, 50, 60],
                                    pageSize: 20,
                                    grouping: true,
                                    exportButton: true,
                                    exportCsv: (columns, data) => {

                                        var objExecel = [
                                            "correlativo",
                                            "creado_el",
                                            "disciplina",
                                            "emitido_por.displayName",
                                            "emitido_por.email",
                                            "estado",
                                            "esta_cerrada",
                                            "fecha_comprometida_cierre",
                                            "fecha_real_cierre",
                                            "obsevacion",
                                            "obsevacion_cierre",
                                            "prioridad",
                                            "proyecto",
                                            "si",
                                            "tipo_inspeccion",
                                            "titulo_inspeccion",
                                        ]
                                        const opts = { fields: objExecel, delimiter: ";", eol: "" };
                                        const parser = new Parser(opts);
                                        const csv = parser.parse(data);

                                        console.log(data);
                                        console.log(csv);
                                        //Generate a file name
                                        var fileName = "CyD_";
                                        //this will remove the blank-spaces from the title and replace it with an underscore
                                        fileName += "MLP_log";

                                        //Initialize file format you want csv or xls
                                        var uri = 'data:text/csv;charset=UTF-8,%EF%BB%BF' + encodeURI(csv);
                                        console.log(uri)

                                        // Now the little tricky part.
                                        // you can use either>> window.open(uri);
                                        // but this will not work in some browsers
                                        // or you will not get the correct file extension    

                                        //this trick will generate a temp <a /> tag
                                        var link = document.createElement("a");
                                        link.href = uri;

                                        //set the visibility hidden so it will not effect on your web-layout
                                        link.style = "visibility:hidden";
                                        link.download = fileName + ".csv";

                                        //this part will append the anchor tag and remove it after automatic click
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                    },
                                    actionsColumnIndex: 0,
                                    rowStyle: rowData => ({
                                        backgroundColor: (rowData.esta_cerrada) ? '#fff' : '#f9ca24'
                                    })
                                }}
                                localization={{
                                    toolbar: {
                                        searchPlaceholder: "Buscar",
                                        exportName: "Exportar CSV"
                                    },

                                    pagination: {
                                        labelRowsSelect: "Filas",
                                        labelDisplayedRows: '{from}-{to} de {count}'
                                    },
                                    grouping: {
                                        placeholder: " Arrastre los encabezados aquí para agrupar "
                                    },
                                    header: {
                                        actions: "Acción"
                                    }

                                }}

                                components={{
                                    Action: props => {

                                        if (props.action.isFreeAction) {
                                            return (
                                                <Tooltip title={props.action.tooltip} aria-label="add">
                                                    <IconButton className={classes.button} aria-label="delete" onClick={(event) => props.action.onClick(event, props.data)}
                                                        color="primary"
                                                        variant="contained"
                                                        style={{ textTransform: 'none' }}
                                                        size="small">
                                                        <RefreshIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )
                                        } else if (props.action.tooltip === "Ver Informe") {
                                            if (typeof props.data.id_pdf === "undefined") {
                                                return (
                                                    <IconButton className={classes.button} aria-label="delete"
                                                        disabled
                                                        variant="contained"
                                                        style={{ textTransform: 'none' }}
                                                        size="small">
                                                        <FileIcon />
                                                    </IconButton>
                                                );
                                            } else {
                                                return (
                                                    <Tooltip title={props.action.tooltip} aria-label="add">
                                                        <IconButton className={classes.button} aria-label="delete" onClick={(event) => props.action.onClick(event, props.data)}
                                                            color="primary"
                                                            variant="contained"
                                                            style={{ textTransform: 'none' }}
                                                            size="small">
                                                            <FileIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                );
                                            }

                                        } else if (props.action.tooltip === "Ver Informe Cierre") {
                                            if (typeof props.data.id_pdf_cierre !== "undefined") {
                                                return (
                                                    <Tooltip title={props.action.tooltip} aria-label="add">
                                                        <IconButton className={classes.button} aria-label="delete" onClick={(event) => props.action.onClick(event, props.data)}
                                                            color="primary"
                                                            variant="contained"
                                                            style={{ textTransform: 'none' }}
                                                            size="small">
                                                            <FileCierreIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                );
                                            } else {
                                                return null;
                                            }

                                        } else if (props.action.tooltip === "Email enviado") {
                                            if (typeof props.data.send_mail !== "undefined") {
                                                if(permiso){
                                                    if (props.data.send_mail) {
                                                        return (
                                                            <Tooltip title={props.action.tooltip} aria-label="add">
                                                                <IconButton className={classes.button} aria-label="delete" onClick={(event) => props.action.onClick(event, props.data)}
                                                                    color="primary"
                                                                    variant="contained"
                                                                    style={{ textTransform: 'none' }}
                                                                    size="small">
                                                                    <Email />
                                                                </IconButton>
                                                            </Tooltip>
                                                        );
                                                    } else {
                                                        return (
                                                            <IconButton className={classes.button} aria-label="delete"
                                                                disabled
                                                                variant="contained"
                                                                style={{ textTransform: 'none' }}
                                                                size="small">
                                                                <Email />
                                                            </IconButton>
                                                        );
                                                    }
                                                }else{
                                                    return null;
                                                }
                                            } else {
                                                return null;
                                            }
                                        }else{
                                            return (<MTableAction {...props} />);   
                                        }
                                    },
                                }}

                                actions={[
                                    {
                                        icon: 'picture_as_pdf',
                                        tooltip: 'Ver Informe',
                                        iconProps: {
                                            color: "primary"
                                        },
                                        onClick: (event, rowData) => {
                                            if (rowData.id_pdf) {
                                                props.onViewerPDF(rowData);
                                            } else {
                                                return null;
                                            }

                                        }
                                    },
                                    {
                                        icon: 'picture_as_pdf',
                                        tooltip: 'Ver Informe Cierre',

                                        iconProps: {
                                            color: "primary"
                                        },
                                        onClick: (event, rowData) => {
                                            console.log("press edit buttom")
                                            props.onViewerPDFCierre(rowData);
                                        }
                                    },
                                    {
                                        icon: 'email',
                                        tooltip: 'Email enviado',
                                        hidden: permiso ?? true,
                                        iconProps: {
                                            color: "primary"
                                        },

                                    },

                                    {
                                        icon: 'refresh',
                                        tooltip: 'Actualizar Log',
                                        isFreeAction: true,
                                        onClick: () => props.onRefreshData(),
                                    }
                                    // {
                                    //     icon: 'edit',
                                    //     tooltip: 'Modificar Proyecto',
                                    //     onClick: (event, rowData) => {
                                    //         console.log("press edit buttom")
                                    //         props.onClickContatoParaEditar(rowData);
                                    //     }
                                    // },
                                    // {
                                    //     icon: 'delete',
                                    //     tooltip: 'Eliminar Proyecto',
                                    //     iconProps:{
                                    //         //color:""
                                    //     },
                                    //     onClick: (event, rowData) => {
                                    //         console.log("press edit buttom",rowData)
                                    //         props.onClickEliminarProyecto(rowData);
                                    //     }
                                    // },

                                ]}

                                columns={[
                                    {
                                        title: 'Código',
                                        field: 'correlativo',
                                        cellStyle: {
                                            // fontSize: '0.8em'
                                            width: '3px',
                                        },

                                    },


                                    {
                                        title: 'Título Inspección',
                                        field: 'titulo_inspeccion',
                                        cellStyle: {
                                            // fontSize: '0.8em'
                                        },
                                    },
                                    {
                                        title: 'Emitido por',
                                        field: 'emitido_por.displayName',
                                        cellStyle: {
                                            // fontSize: '0.8em'
                                        },
                                    },
                                    {
                                        title: 'Tipo Inspección',
                                        field: 'tipo_inspeccion',
                                        cellStyle: {
                                            // fontSize: '0.8em'
                                        },
                                    },
                                    {
                                        title: 'Disciplina',
                                        field: 'disciplina',
                                        cellStyle: {
                                            // fontSize: '0.8em'
                                        },
                                    },
                                    {
                                        title: 'Prioridad',
                                        field: 'prioridad',
                                        cellStyle: {
                                            // fontSize: '0.8em'
                                        },
                                    },
                                    {
                                        title: 'Fecha de Creación',
                                        field: 'creado_el',
                                        render: rowData => generarFechaView(rowData),
                                        cellStyle: {
                                            // fontSize: '0.9em'
                                        },
                                        type: 'date'
                                    },
                                    {
                                        title: 'Fecha comprometida de cierre',
                                        field: 'fecha_comprometida_cierre',
                                        render: rowData => generarFechaComprometidaView(rowData),
                                        cellStyle: {
                                            // fontSize: '0.9em'
                                        },
                                        type: 'date'
                                    },
                                    {
                                        title: 'Fecha de cierre',
                                        field: 'fecha_real_cierre',
                                        render: rowData => generarFechaCierreView(rowData),
                                        cellStyle: {
                                            // fontSize: '0.9em'
                                        },
                                        type: 'date'
                                    },
                                    {
                                        title: 'Inspección Cerrada',
                                        field: 'esta_cerrada',
                                        cellStyle: {
                                            width: '3px',
                                            // fontSize: '0.8em'
                                        },
                                        render: rowData => generarEstadoCerrado(rowData),
                                    },
                                ]}
                                data={data}
                            />
                        </Grid></Grid>

                    {/* <iframe src="https://drive.google.com/a/cydingenieria.cl/embeddedfolderview?id=1OO-qmMMK3g7rMkQgOFf2ZXd6RmIs5EfB#grid" style={{height:"100vh",width:"50vw"}}></iframe> */}
                </Container >
            </main>
        </Fragment >
    );
}

export default TablaLog;