import React, { Fragment } from 'react';

import { makeStyles } from '@material-ui/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import Paper from '@material-ui/core/Paper';
import CheckCircleOutlineSharp from '@material-ui/icons/CheckCircleOutlineSharp';
import ErrorOutlineSharpIcon from '@material-ui/icons/ErrorOutlineSharp';
import CancelPresentationSharpIcon from '@material-ui/icons/CancelPresentationSharp';

import "../../../App.css"

const style = makeStyles(theme => ({
    toolbar: theme.mixins.toolbar,
    content: {
        flex: 1,
        padding: theme.spacing(3),

    },
    contenedor: {
        display: 'flex'
    },
    elemento: {
        width: '25%'
    },
    elemento10: {
        width: '10%'
    },
    padding16: {
        paddingLeft: '16',
        paddingRight: '16'
    },
    elementoContato: {
        width: '45%'
    },
    chip: {
        margin: theme.spacing(1),
    },
    main: {
        paddingLeft: theme.spacing(2),

    },
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        textAlign: 'right',
    },
}))


function Dashboard(props) {
    const classes = style();
    const [age, setAge] = React.useState('');
    const handleChange = event => {
        setAge(event.target.value);
    };
    const inputLabel = React.useRef(null);



    const {
        data,
        total,
        totalCierre,
        totalPendienteCierre
        // rol
    } = props;
    
    var tituloTabla = "Listado de Inspecciones Realizadas";
    return (
        <div className={classes.root}>
            <Grid container alignItems="flex-end" spacing={3}>
                <Grid item xs={3}>
                    <Paper className={classes.paper} style={{ background: "#2c3e50" }}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <CheckCircleOutlineSharp
                                    style={{ color: "#fff" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm container>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Grid item xs>
                                        <Typography color="secondary" style={{ color: "#fff" }} variant="body2">Total Reportes<br></br> Emitidos </Typography>
                                        <Typography color="secondary" style={{ color: "#fff" }} variant="h4">{total} </Typography>
                                    </Grid>

                                </Grid>

                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={3} >
                    <Paper className={classes.paper} style={{ background: "#27ae60" }}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <CheckCircleOutlineSharp
                                    style={{ color: "#fff" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm container>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Grid item xs>
                                        <Typography color="secondary" style={{ color: "#fff" }} variant="body2">Total Reportes<br></br> Cerrados </Typography>
                                        <Typography color="secondary" style={{ color: "#fff" }} variant="h4">{totalCierre} </Typography>
                                    </Grid>

                                </Grid>

                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={3} xl={2} >
                    <Paper className={classes.paper} style={{ background: "#c0392b" }}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <CheckCircleOutlineSharp
                                    style={{ color: "#fff" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm container>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Grid item xs>
                                        <Typography color="secondary" style={{ color: "#fff" }} variant="body2">Total Reportes <br></br>Pendiente de Cierre </Typography>
                                        <Typography color="secondary" style={{ color: "#fff" }} variant="h4">{totalPendienteCierre} </Typography>
                                    </Grid>

                                </Grid>

                            </Grid>
                        </Grid>
                    </Paper>
                    
                </Grid>

                {/* <Grid item xs={5} xl={6}>
                    <Paper className={classes.paper} style={{textAlign: 'left'}} >
                        <Grid container
                            direction="row"
                            spacing={1}>
                            <Grid item lg={4}>
                                <FormControl
                                    style={{ width: "100%" }}
                                    // className={classes.formControl}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                                        Estado
                                    </InputLabel>
                                    <Select
                                        autoWidth={true}
                                        value={age}
                                        name="estado"
                                        onChange={handleChange}
                                        inputProps={{
                                            id: 'estado-simple',
                                        }}>
                                        <MenuItem key={1} value={10}>Ten</MenuItem>
                                        <MenuItem key={2} value={10}>Ten</MenuItem>
                                        <MenuItem key={3} value={10}>Ten</MenuItem>

                                    </Select>

                                </FormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <FormControl
                                    style={{ width: "100%" }}
                                    // className={classes.formControl}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                                        Estado
                                    </InputLabel>
                                    <Select
                                        autoWidth={true}
                                        value={age}
                                        name="estado"
                                        onChange={handleChange}
                                        inputProps={{
                                            id: 'estado-simple',
                                        }}>
                                        <MenuItem key={1} value={10}>Ten</MenuItem>
                                        <MenuItem key={2} value={10}>Ten</MenuItem>
                                        <MenuItem key={3} value={10}>Ten</MenuItem>

                                    </Select>

                                </FormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <FormControl
                                    style={{ width: "100%" }}
                                    // className={classes.formControl}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                                        Estado
                                    </InputLabel>
                                    <Select
                                        autoWidth={true}
                                        value={age}
                                        name="estado"
                                        onChange={handleChange}
                                        inputProps={{
                                            id: 'estado-simple',
                                        }}>
                                        <MenuItem key={1} value={10}>Ten</MenuItem>
                                        <MenuItem key={2} value={10}>Ten</MenuItem>
                                        <MenuItem key={3} value={10}>Ten</MenuItem>

                                    </Select>

                                </FormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <FormControl
                                    style={{ width: "100%" }}
                                    // className={classes.formControl}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                                        Estado
                                    </InputLabel>
                                    <Select
                                        autoWidth={true}
                                        value={age}
                                        name="estado"
                                        onChange={handleChange}
                                        inputProps={{
                                            id: 'estado-simple',
                                        }}>
                                        <MenuItem key={1} value={10}>Ten</MenuItem>
                                        <MenuItem key={2} value={10}>Ten</MenuItem>
                                        <MenuItem key={3} value={10}>Ten</MenuItem>

                                    </Select>

                                </FormControl>
                            </Grid>
                            <Grid item lg={4}>
                                <FormControl
                                    style={{ width: "100%" }}
                                    // className={classes.formControl}
                                    margin="normal"
                                    variant="outlined"
                                >
                                    <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                                        Estado
                                    </InputLabel>
                                    <Select
                                        autoWidth={true}
                                        value={age}
                                        name="estado"
                                        onChange={handleChange}
                                        inputProps={{
                                            id: 'estado-simple',
                                        }}>
                                        <MenuItem key={1} value={10}>Ten</MenuItem>
                                        <MenuItem key={2} value={10}>Ten</MenuItem>
                                        <MenuItem key={3} value={10}>Ten</MenuItem>

                                    </Select>

                                </FormControl>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
             */}
            </Grid>
        </div>
    );
}

export default Dashboard;