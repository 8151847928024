import React, { Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import VerifiedUser from '@material-ui/icons/VerifiedUser';

const style = makeStyles(theme => ({
    toolbar: theme.mixins.toolbar,
    content: {
        flex: 1,
        padding: theme.spacing(3),
    },
    contenedor: {
        display: 'flex'
    },
    elemento: {
        width: '25%'
    },
    elemento10: {
        width: '10%'
    },
    padding16: {
        paddingLeft: '16',
        paddingRight: '16'
    },
    elementoContato: {
        width: '45%'
    },
    chip: {
        margin: theme.spacing(1),
    },
    main: {
        paddingLeft: theme.spacing(9),
        paddingTop: theme.spacing(2),
    }
}));

function Page(props) {
    const classes = style();

    return (
        <Fragment>
            <main style={{ paddingTop: '40vh', paddingBottom: "45vh" }}>
                <Container maxWidth="xl" className={classes.main} >
                    <CssBaseline />
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                    >
                        <VerifiedUser style={{ fontSize: 70 }} color="primary"></VerifiedUser>
                        <Typography variant="h6" gutterBottom style={{ width: '30vw' }} align='center'>
                            Tenemos problemas con los permisos asignado por favor póngase en contacto con el equipo Cydocs.cl
                        </Typography>
                    </Grid>

                </Container >
            </main>
        </Fragment >
    );
}

export default Page;