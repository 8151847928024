import Axios from "../axios";

export const getUsuario = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/usuarios/uid/${id}`, {
        })
        .then((res)=>{
            if(res.data) resolve(res.data);
            else resolve(null);
        })
        .catch((error)=>{
            console.log(error);
            resolve(error);
        });
    });
}

export const getContratosUsuario = async (id) => {
    return new Promise((resolve, reject) => {
        var base = process.env.REACT_APP_DB_URL;
        Axios.get(`${base}/usuarios/${id}/contratos-visibles`, {
        })
        .then((res)=>{
            if(res.data && res.data.contratos_visibles_ref) resolve(res.data.contratos_visibles_ref);
            else resolve(null);
        })
        .catch((error)=>{
            console.log(error);
            resolve(error);
        });
    });
}

