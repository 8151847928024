import { auth, realTime } from './firebase.js';
import axios from 'axios';
import fetch from 'node-fetch';

// const baseUrl = 'https://us-central1-cydingenieria-proyectos.cloudfunctions.net/widgets';
const baseUrl = 'https://api-usuarios.cydocs.cl/login';
const loginURL = 'https://account.cydocs.cl';

const getQueryVariable = function (variable) {
    const query = window.location.search.substring(1);
    //console.log(query)//"app=article&act=news_content&aid=160990"
    const vars = query.split("&");
    //console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        //console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
        if (pair[0] === variable) { return pair[1]; }
    }
    return (false);
}


export const loginUsuario = async (email, password) => {
    var token = "";
    return auth.signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
            return auth.currentUser.getIdToken(true)
        })
        .then((id_token) => {
            token = id_token;
            return fetch(`${baseUrl}/getCSRFToken`, {
                method: 'get',
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
        })
        .then(res => res.json())
        .then((res) => {
            return axios.get(`${baseUrl}/sessionLogin`, {
                withCredentials: true,
                crossDomain: true,
                xsrfCookieName: 'csrfToken',
                xsrfHeaderName: 'CSRF-Token',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'CSRF-Token': res.csrfToken
                }
            })
        });
}

export const checkSession = async () => {
    const csrfToken = getQueryVariable("csrfToken") || localStorage.getItem("csrfToken") || "";
    localStorage.setItem("csrfToken", csrfToken);
    return fetch(`${baseUrl}/verifySessionCookie`, {
        method: 'get',
        credentials: 'include',
        headers: {
            'Authorization': `Bearer ${csrfToken}`
        },
    })
        .then(res => {
            if (res.status === 200) {
                return res.json()
            } else {
                setTimeout(() => {
                    window.location.href = `${loginURL}/?origin=${window.location.href.split("?")[0]}`;
                }, 10000);

            }
        })
        .then((res) => {
            if (res) {
                return auth.signInWithCustomToken(res.token);
            } else {
                return null;
            }
        });
}
export const getCurrentUser = async () => {
    return auth.currentUser.getIdTokenResult()
}

export const getCanPermisos = async (rol) => {
    var permiso = await realTime.ref("/permisos/" + rol).once("value").then();
    if (permiso.val() !== null) {
        if (typeof permiso.val().portafolio_contratos === "undefined") {
            return {};
        } else {
            return permiso.val().portafolio_contratos.permisos;
        }
    }
}

export const getCanPermisosGeneral = async (rol) => {
    console.log(rol);
    var permiso = await realTime.ref("/permisos/" + rol).once("value").then();
    if (permiso.val() !== null) {
        if (typeof permiso.val().cartas === "undefined") {
            return false;
        } else {
            return permiso.val().cartas.acceso;
        }
    } else {
        return false;
    }
}
export const redirecTo = async (destiny, contrato, proyecto) => {
    var url = `${baseUrl}/redirectToApp`;
    console.log(url);
    console.log(destiny);
    if (destiny) {
        url += `?redirect-url=${destiny}`;
    }
    if (proyecto && contrato) {
        if (destiny) {
            url += `&proyecto=${proyecto}&contrato=${contrato}`;
        } else {
            url += `proyecto=${proyecto}&contrato=${contrato}`;
        }
    }
    console.log(url);
    window.location.href = url;
}

export const cerrarSesion = async () => {
    var token = "";
    localStorage.setItem("csrfToken", "");

    if (auth.currentUser) {
        return auth.currentUser.getIdToken(true)
            .then((id_token) => {
                token = id_token;
                return fetch(`${baseUrl}/getCSRFToken`, {
                    method: 'get',
                    credentials: 'include',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                });
            })
            .then(res => res.json())
            .then((res) => {
                return fetch(`${baseUrl}/sessionLogout?_csrf=${res.csrfToken}`, {
                    method: 'post',
                    credentials: 'include',
                });
            })
            .then((res) => auth.signOut());
    } else {
        return null;
    }

}
