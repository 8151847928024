import React, { useState } from 'react';
import Page from './page';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from "yup";
// import { accionCloseSelectorContrato } from '../../redux/actions/selectorContratos';
import { setContratoProyecto } from '../../redux/actions/actionsSesion';
import { getContratosUsuario, getUsuario } from '../../services/database/usuario';
import { getProyectosContrato } from '../../services/database/contrato';
import { getProyecto } from '../../services/database/proyecto';
import { auth } from '../../services/firebase';
import ContratoModel from '../../model/ContratoModel';
import ProyectoModel from '../../model/ProyectoModel';
import { setError } from '../../redux/actions/actionsError';
import { accionCloseSelectorContrato } from './actions';
import { stopLoading } from '../../redux/actions/actionsLoading';
export default function SelectorContrato(props) {

    const [suggestionsContrato, setSuggestionsContrato] = useState(null);
    const [suggestionsProyecto, setSuggestionsProyecto] = useState(null);
    const [proyectos, setProyectos] = useState([]);
    const [contratos, setContratos] = useState([]);
    const [formik, setFormik] = useState(null);

    const { contrato, proyecto, open } = useSelector(state => ({
        contrato: state.sesion.contrato,
        proyecto: state.sesion.proyecto,
        open: state.openSelectorContrato
    }));

    const dispatch = useDispatch();

    const handleEnterDialog = async () => {
        // const { setAccessError } = props;
        try {
            // dispatch(accionDialogoMensaje("OPEN", "No se han podido cargar los contratos. Contactese con el administrador", "Error"));
            const uid = auth.currentUser.uid;
            if (!uid) {
                throw new Error("No se ha recurar usuario de firebase");
            }
            const usuario = await getUsuario(uid);
            if (!usuario) {
                throw new Error("No se ha recurar usuario de proyectos");
            }
            if (!contrato) {
                localStorage.setItem("contrato", usuario.contratos_visibles_ref[0]);
            } else {
                localStorage.setItem("contrato", contrato._id);
            }
            if (!proyecto) {
                localStorage.setItem("proyecto", usuario.proyectos_visibles_ref[0]);
            } else {
                localStorage.setItem("proyecto", proyecto._id);
            }
            const contratosUsuario = await getContratosUsuario(usuario._id);
            if (!contratosUsuario || contratosUsuario.length <= 0) {
                // dispatch(accionDialogoMensaje("OPEN", "El usuario no tiene ningún contrato asignado. Contactese con el administrador.", "Error"));
                dispatch(setError("403", "El usuario no tiene ningún contrato asignado. Contactese con el administrador."));
                handleCloseDialog();
                // return;
            }
            let proyectosContrato = [];
            const suggestionsContratoUsuario = [{ label: "", value: "" }];
            const suggestionsProyectoUsuario = [{ label: "", value: "" }];
            let contratoSelecionado = { label: "", value: "" };
            let proyectoSeleccionado = { label: "", value: "" };
            for (let i = 0; i < contratosUsuario.length; i++) {
                const suggestion = { label: contratosUsuario[i].codigo + " " + contratosUsuario[i].nombre, value: i };
                suggestionsContratoUsuario.push(suggestion);
                if (contrato && contratosUsuario[i]._id === contrato._id) {
                    contratoSelecionado = suggestion;
                }
            }
            let contratoAux = Array.from(contratosUsuario).find(c => c._id == localStorage.getItem("contrato"));
            if (contratoAux && contratoAux._id) {
                for (let i = 0; i < contratoAux.proyectos_ref.length; i++) {
                    const suggestion = { label: contratoAux.proyectos_ref[i].codigo + " " + contratoAux.proyectos_ref[i].nombre, value: i };
                    suggestionsProyectoUsuario.push(suggestion);
                    if (proyecto && contratoAux.proyectos_ref[i]._id === proyecto._id) {
                        proyectoSeleccionado = suggestion;
                    }
                }
            }
            formik.resetForm({
                contrato: contratoSelecionado,
                proyecto: proyectoSeleccionado
            });
            setContratos(contratosUsuario);
            setSuggestionsContrato(suggestionsContratoUsuario);
            setProyectos(contratoAux.proyectos_ref);
            setSuggestionsProyecto(suggestionsProyectoUsuario);
        }
        catch (e) {
            console.log("ERROR EN SELECTOR CONTRATO", e);
            dispatch(setError("404", "No se han podido cargar los contratos. Contactese con el administrador."));
            // dispatch(accionDialogoMensaje("OPEN", "No se han podido cargar los contratos. Contactese con el administrador", "Error"));
        }
    }


    const handleSubmit = async (values, formikBag) => {
        // const { setLoading } = props;
        const proyectoDB = await getProyecto(proyectos[values.proyecto.value]._id);
        const proyecto = new ProyectoModel(proyectoDB);
        const contrato = new ContratoModel(contratos[values.contrato.value]);
        localStorage.setItem("proyecto", proyecto._id);
        localStorage.setItem("contrato", contrato._id);
        dispatch(setContratoProyecto(contrato, proyecto));
        // setLoading(false);
        dispatch(stopLoading());
        dispatch(accionCloseSelectorContrato());
        handleCloseDialog();
    }

    const handleOnChange = async ({ name, value }) => {
        if (name === "contrato") {
            if (contratos && contratos.length > 0 && contratos[value]) {
                const id = contratos[value]._id;
                // const proyectosContrato = await getProyectosContrato(id);
                const proyectosContrato = contratos[value].proyectos_ref ? contratos[value].proyectos_ref : [];
                const suggestionsProyectoUsuario = [];
                for (let i = 0; i < proyectosContrato.length; i++) {
                    const suggestion = { label: proyectosContrato[i].codigo + " " + proyectosContrato[i].nombre, value: i };
                    suggestionsProyectoUsuario.push(suggestion);
                }
                setProyectos(proyectosContrato);
                setSuggestionsProyecto(suggestionsProyectoUsuario);
            }
        }
    };

    const handleChange = (e, { name, value }) => {
        formik.setFieldValue(name, value);
        e.persist();
    };

    const handleCloseDialog = () => {
        console.log("cerrar");
        dispatch(accionCloseSelectorContrato());
    }

    // const { open } = props;
    const validationSchema = Yup.object({
        contrato: Yup.string("Seleccionar Contrato").required("Campo requerido"),
        proyecto: Yup.string("Seleccionar Proyecto").required("Campo requerido")
    });
    const values = {
        contrato: { label: "", value: "" },
        proyecto: { label: "", value: "" }
    };

    return (

        <React.Fragment>
            <Formik
                ref={(ref) => setFormik(ref)}
                onSubmit={handleSubmit}
                initialValues={values}
                validationSchema={validationSchema}
                onChange={handleChange}
                render={props =>
                    <Page
                        handleEnter={handleEnterDialog}
                        suggestionsContrato={suggestionsContrato}
                        suggestionsProyecto={suggestionsProyecto}
                        contrato={contrato}
                        proyecto={proyecto}
                        titulo={"Seleccionar Contrato"}
                        open={open}
                        handleOnChange={handleOnChange}
                        handleClose={handleCloseDialog}
                        {...props}
                    />}
            />
        </React.Fragment>
    );

}