//PROGRAMA
export const CARGAR_PROGRAMA = 'cargarPrograma';
export const VER_ACTIVIDAD = 'verActividad';
export const EDITAR_ACTIVIDAD = 'editarActividad';
export const AGREGAR_ACTIVIDAD = 'agregarActividad';
export const ELIMINAR_ACTIVIDAD = 'eliminarActividad';
//PRESUPUESTO
export const CARGAR_PRESUPUESTO = 'cargarPresupuesto';
export const VER_ITEM_PRESUPUESTO = 'verItemPresupuesto';
export const EDITAR_ITEM_PRESUPUESTO = 'editarItemPresupuesto';
export const AGREGAR_ITEM_PRESUPUESTO = 'agregarItemPresupuesto';
export const ELIMINAR_ITEM_PRESUPUESTO = 'eliminarItemPresupuesto';
//RECURSOS
export const VER_RECURSO = 'verRecurso';
export const EDITAR_RECURSO = "editarRecurso";
export const AGREGAR_RECURSO = "agregarRecurso";
export const ELIMINAR_RECURSO = "eliminarRecurso";
//AVANCES ACTIVIDAD
export const VER_AVANCES_ACTIVIDAD = "verAvancesActividad";
export const EDITAR_AVANCES_ACTIVIDAD = "editarAvancesActividad";
export const AGREGAR_AVANCES_ACTIVIDAD = "agregarAvancesActividad";
export const ELIMINR_AVANCES_ACTIVIDAD = "eliminarAvancesActividad";
//AVANCES POD
export const VER_POD = "verPOD";
export const AGREGAR_POD = "agregarPOD";

export const VER_INSPECCION =  "verInspeccion";
export const VER_DASHBOARD_INSPECCION  =  "verDashBoardInspeccion";
export const VER_ENVIO_INSPECCION  =  "verEnvioInspeccion";
