import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    InputLabel,
    LinearProgress
} from '@material-ui/core';
import ReactSelect from 'react-select';

function Page(props) {
    const {
        values,
        titulo,
        open,
        handleSubmit,
        handleClose,
        contrato,
        proyecto,
        suggestionsContrato,
        suggestionsProyecto,
        isValid,
        handleEnter
    } = props;

    const change = (name, e) => {
        const { setFieldTouched, handleOnChange, handleChange, setFieldValue } = props;
        setFieldTouched(name, true, false);
        if (name === "proyecto") {
            setFieldValue(name, e);
            handleChange(e);
            handleOnChange({ name: name, value: e.value });
            return;
        }
        if (name === "contrato") {
            setFieldValue(name, e);
            setFieldValue('proyecto', '');
            handleChange(e);
            handleOnChange({ name: name, value: e.value });
            return;
        }
        handleChange(e);
        e.persist();
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            onEntered={handleEnter}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"lg"}
            fullWidth={true}
        >
            {(suggestionsContrato && suggestionsContrato.length > 0) &&
                <form onSubmit={handleSubmit} autoComplete="off">
                    <DialogTitle id="alert-dialog-title">{titulo}</DialogTitle>
                    <DialogContent>
                        <Grid container
                            direction="row"
                            spacing={1}>
                            <Grid item xs={12} lg={12}>
                                <InputLabel htmlFor="contrato">Contrato</InputLabel>
                                <ReactSelect
                                    searchable={true}
                                    value={values.contrato}
                                    onChange={change.bind(null, "contrato")}
                                    name="contrato"
                                    placeholder="Buscar Contrato"
                                    options={suggestionsContrato}
                                    noOptionsMessage={() =>"No hay resultados"}
                                    menuPosition="absolute"
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                    menuPortalTarget={document.body}
                                />
                            </Grid>
                        </Grid>
                        {(values.contrato !== "" && suggestionsProyecto && suggestionsProyecto.length > 0) &&
                            <Grid container
                                direction="row"
                                spacing={1}>
                                <Grid item xs={12} lg={12}>
                                    <InputLabel htmlFor="proyecto">Proyecto</InputLabel>
                                    <ReactSelect
                                        searchable={true}
                                        value={values.proyecto}
                                        onChange={change.bind(null, "proyecto")}
                                        name="proyecto"
                                        placeholder="Buscar Proyecto"
                                        options={suggestionsProyecto}
                                        noOptionsMessage={() =>"No hay resultados"}
                                        menuPosition="absolute"
                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                        menuPortalTarget={document.body}
                                    />
                                </Grid>
                            </Grid>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClose}
                            color="primary"
                            disabled={(!proyecto || !contrato)}
                        >
                            Cancelar
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={!isValid}
                        >
                            Aceptar
                        </Button>
                    </DialogActions>
                </form>
            }
            {!suggestionsContrato &&
                <div>
                    <DialogTitle id="alert-dialog-title">
                        Cargando Contratos
                    </DialogTitle>
                    <DialogContent>
                        <LinearProgress color="primary" />
                    </DialogContent>
                    <DialogActions>
                    </DialogActions>
                </div>
            }
        </Dialog >
    );
}

export default Page;
