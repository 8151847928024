export const SET_ERROR = 'accionSetError';
export const CLEAR_ERROR = 'accionClearError';

export const setError = (code, error) => ({
    type: SET_ERROR,
    code: code,
    error: error
});
export const clearError = (code) => ({
    type: CLEAR_ERROR,
});