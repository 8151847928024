import { INIT_LOGIN, FINISH_LOGIN } from '../actions/actionsLogin';

const defaultState = false;

export default function reducerLogin(state = defaultState, { type }) {
    switch (type) {
        case INIT_LOGIN: {
            return true;
        }
        case FINISH_LOGIN: {
            return false;
        }
        default:
            return state;
    }
}
