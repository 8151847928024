import React, { useState, useEffect } from "react";
import Page from "./page";

function Index(props) {
  const [TotalReportes, SetTotalReportes] = useState(0);
  const [ReportesAbiertos, SetReportesAbiertos] = useState(0);
  const [ReportesCerrados, SetReportesCerrados] = useState(0);

  let {
    inspecciones_filter: InspeccionesFilter,
    is_loading: IsLoading,
  } = props;

  useEffect(() => {
    let totalReportes = Array.from(InspeccionesFilter).length;
    SetTotalReportes(totalReportes);

    let reportesAbiertos = Array.from(InspeccionesFilter).filter(l => l.esta_cerrada === false).length;
    SetReportesAbiertos(reportesAbiertos);

    let reportesCerrados = Array.from(InspeccionesFilter).filter(l => l.esta_cerrada === true).length;
    SetReportesCerrados(reportesCerrados);
  }, [InspeccionesFilter]);

  return (
    <Page total_reportes={TotalReportes} reportes_abiertos={ReportesAbiertos} reportes_cerrados={ReportesCerrados} is_loading={IsLoading} />
  );
}

export default Index;