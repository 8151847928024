import { OPEN_CONTENIDO, CLOSE_CONTENIDO } from './actionsEjemplo';


const defaultState = {
  openCerrarContenido: false,
  bundle: null
};

export default function reducerCartas(state = defaultState, { type, bundle }) {
  switch (type) {
    case OPEN_CONTENIDO: {
      return { ...state, openCerrarContenido: true, bundle: bundle };
    }
    case CLOSE_CONTENIDO: {
      return { ...state, openCerrarContenido: false, bundle: null };
    }
    default:
      return state;
  }
}