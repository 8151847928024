import { INIT_LOGOUT, FINISH_LOGOUT } from '../actions/actionsLogout';

const defaultState = false;

export default function reducerLogOut(state = defaultState, { type }) {
    switch (type) {
        case INIT_LOGOUT: {
            return true;
        }
        case FINISH_LOGOUT: {
            return false;
        }
        default:
            return state;
    }
}
