
/**
 * @author Luis San Martín
 */

export default class ProyectoModel {

    constructor(json) {
        this._id = "";
        this.carpetas = [];
        this.cartas_ref = [];
        this.codigo = "";
        this.contratista_ref = [];
        this.contrato_ref = [];
        this.incidentes_ref = [];
        this.is_eliminado = false;
        this.no_conformidades_ref = [];
        this.nombre = "";
        this.presupuesto_actual_ref = "";
        this.presupuestos_ref = [];
        this.programa_actual_ref = null;
        this.programa_siguiente_ref = null;
        this.programa_anterior_ref = null;
        this.programa_cerrado = false;
        this.programas_ref = [];
        this.recursos_ref = [];
        this.superintendencia_ref = {};
        if (json) this.build(json);
    }

    build(json) {
        this._id = json._id ? json._id : "";
        this.carpetas = json.carpetas ? json.carpetas : [];
        this.cartas_ref = json.cartas_ref ? json.cartas_ref : [];
        this.codigo = json.codigo ? json.codigo : "";
        this.contratista_ref = json.contratista_ref ? json.contratista_ref : [];
        this.contrato_ref = json.contrato_ref ? json.contrato_ref : [];
        this.incidentes_ref = json.incidentes_ref ? json.incidentes_ref : [];
        this.is_eliminado = json.is_eliminado ? json.is_eliminado : false;
        this.no_conformidades_ref = json.no_conformidades_ref ? json.no_conformidades_ref : [];
        this.nombre = json.nombre ? json.nombre : "";
        this.presupuesto_actual_ref = json.presupuesto_actual_ref ? json.presupuesto_actual_ref : "";
        this.presupuestos_ref = json.presupuestos_ref ? json.presupuestos_ref : [];
        this.programa_actual_ref = json.programa_actual_ref ? json.programa_actual_ref : null;
        this.programa_siguiente_ref = json.programa_siguiente_ref ? json.programa_siguiente_ref : null;
        this.programa_anterior_ref = json.programa_anterior_ref ? json.programa_anterior_ref : null;
        this.programa_cerrado = json.programa_cerrado ? json.programa_cerrado : false;
        this.programas_ref = json.programas_ref ? json.programas_ref : [];
        this.recursos_ref = json.recursos_ref ? json.recursos_ref : [];
        this.superintendencia_ref = json.superintendencia_ref ? json.superintendencia_ref : {};
    }


}