import React from 'react';
import Page from './page';
import { accionOpenMenuLateral, accionCloseMenuLateral } from '../drawerMain/actions'
import { accionOpenSelectorContrato } from '../selectorContrato/actions';
import { useDispatch, useSelector } from 'react-redux';


export default function Appbar(props) {

    const { openMenuLateral, contrato, proyecto, usuario } = useSelector(state => ({
        openMenuLateral: state.openMenuLateral,
        contrato: state.sesion.contrato,
        proyecto: state.sesion.proyecto,
        usuario: state.sesion.usuario
    }));

    const dispatch = useDispatch();

    const onClickMenuLateral = () => {
        if (openMenuLateral) dispatch(accionCloseMenuLateral());
        else dispatch(accionOpenMenuLateral());
    }

    const handleOpenSelector = async () => {
        dispatch(accionOpenSelectorContrato());
    }


    return (
        <Page
            tituloAppBar={props.nombreModulo}
            nombreUsuario={usuario ? usuario.nombre : ""}
            urlImagenUsuario={usuario ? usuario.photoUrl : ""}
            cantidadMensajes={100}
            cantidadNotificaciones={0}
            notificacionesIsVisible="true"
            mensajesIsVisisble="true"
            onClickMenuLateral={onClickMenuLateral}
            // onClickMensajes={this.onClickMensajes}
            // onClickNotificaciones={this.onClickNotificaciones}
            isAutheticadoUser="true"
            handleOpenSelector={handleOpenSelector}
            contrato={contrato}
            proyecto={proyecto}

        />
    );

}