import React from "react";
import Icon from '@mdi/react';
import { mdiLoading } from '@mdi/js';
import { Grid, Paper } from "@material-ui/core";

function Page(props) {
  let {
    total_reportes: TotalReportes,
    reportes_abiertos: ReportesAbiertos,
    reportes_cerrados: ReportesCerrados,
    is_loading: IsLoading,
  } = props;

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Paper elevation={10} className="text-center" style={{ height: "168px" }}>
          <Grid container>
            <Grid item xs={12}>
              <b style={{ fontSize: "34px" }}>Total Reportes</b>
            </Grid>
            <Grid item xs={12}>
              {IsLoading ? <Icon path={mdiLoading} size={"80px"} color="blue" spin={true} className="m-2" /> : <label style={{ fontSize: "72px" }}>{TotalReportes}</label>}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item container xs={6}>
        <Grid item xs={12} className="mb-2">
          <Paper elevation={10}>
            <Grid container>
              <Grid item xs={8} className="mt-4">
                <b className="ml-2" style={{ fontSize: "18px" }}>Reportes Abiertos</b>
              </Grid>
              <Grid item xs={4} className="text-right" style={{ height: "76px" }}>
                {IsLoading ? <Icon path={mdiLoading} size={"50px"} color="blue" spin={true} className="mt-3 mr-3" /> : <label className="mt-2 mr-2" style={{ fontSize: "36px" }}>{ReportesAbiertos}</label>}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} className="mt-2">
          <Paper elevation={10}>
            <Grid container>
              <Grid item xs={8} className="mt-4">
                <b className="ml-2" style={{ fontSize: "18px" }}>Reportes Cerrados</b>
              </Grid>
              <Grid item xs={4} className="text-right" style={{ height: "76px" }}>
                {IsLoading ? <Icon path={mdiLoading} size={"50px"} color="blue" spin={true} className="mt-3 mr-3" /> : <label className="mt-2 mr-2" style={{ fontSize: "36px" }}>{ReportesCerrados}</label>}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Page;