import React, { useState, useEffect } from "react";
import Page from "./page";
import Moment from "moment";
import { useSelector } from "react-redux";

function Index(props) {
  const { Proyecto, contrato, sesion } = useSelector(state => ({
    contrato: state.sesion.contrato,
    Proyecto: state.sesion.proyecto,
    sesion: state.sesion,
  }));

  const [Proyectos, SetProyectos] = useState([]);
  // const [Proyecto, SetProyecto] = useState("");
  const [Areas, SetAreas] = useState([]);
  const [Area, SetArea] = useState("");
  const [Superintendencias, SetSuperintendencias] = useState([]);
  const [Superintendencia, SetSuperintendencia] = useState("");
  const [Disciplinas, SetDisciplinas] = useState([]);
  const [Disciplina, SetDisciplina] = useState("");
  const [TiposInspeccion, SetTiposInspeccion] = useState([]);
  const [TipoInspeccion, SetTipoInspeccion] = useState("");
  const [Emisores, SetEmisores] = useState([]);
  const [Emisor, SetEmisor] = useState("");
  const [Contratistas, SetContratistas] = useState([]);
  const [Contratista, SetContratista] = useState("");
  const [Estados, SetEstados] = useState([]);
  const [Estado, SetEstado] = useState("");
  const [Prioridades, SetPrioridades] = useState([]);
  const [Prioridad, SetPrioridad] = useState("");
  const [FechaComprometidaCierreDesde, SetFechaComprometidaCierreDesde] = useState(null);
  const [FechaComprometidaCierreHasta, SetFechaComprometidaCierreHasta] = useState(null);
  const [FechaRealCierreDesde, SetFechaRealCierreDesde] = useState(null);
  const [FechaRealCierreHasta, SetFechaRealCierreHasta] = useState(null);

  let {
    inspecciones: Inspecciones,
    set_inspecciones_filter: SetInspeccionesFilter,
    is_loading: IsLoading,
  } = props;

  useEffect(() => {
    LoadProyectos()
      .then(proyectos => SetProyectos(proyectos))
      .catch(() => SetProyectos([]));

    SetAreas([])

    LoadSuperintendencias()
      .then(superintendencias => SetSuperintendencias(superintendencias))
      .catch(() => SetSuperintendencias([]));

    let disciplinas = LoadDisciplinas(Inspecciones);
    SetDisciplinas(disciplinas);

    let tiposInspeccion = LoadTiposInspeccion(Inspecciones);
    SetTiposInspeccion(tiposInspeccion);

    let emisores = LoadEmisores(Inspecciones);
    SetEmisores(emisores);

    SetContratistas([]);

    let estados = LoadEstados(Inspecciones);
    SetEstados(estados);

    let prioridades = LoadPrioridades(Inspecciones);
    SetPrioridades(prioridades);
  }, [IsLoading]);

  useEffect(() => {
    let inspeccionesFilter = Array.from(Inspecciones);
    console.log("Total inspecciones: ", inspeccionesFilter.length);
    if (Proyecto) {
      console.log("Proyecto: ", Proyecto);
      inspeccionesFilter = inspeccionesFilter.filter(i => i.id_proyecto === Proyecto._id);
      console.log("Total inspecciones: ", inspeccionesFilter.length);
    }
    if (Area) {
      console.log("Área: ", Area);
      inspeccionesFilter = inspeccionesFilter.filter(i => i.area === Area);
      console.log("Total inspecciones: ", inspeccionesFilter.length);
    }
    if (Superintendencia) {
      console.log("Superintendencia: ", Superintendencia);
      inspeccionesFilter = inspeccionesFilter.filter(i => i.superintendencia === Superintendencia);
      console.log("Total inspecciones: ", inspeccionesFilter.length);
    }
    if (Emisor) {
      console.log("Emisor: ", Emisor);
      inspeccionesFilter = inspeccionesFilter.filter(i => i.emitido_por.email === Emisor);
      console.log("Total inspecciones: ", inspeccionesFilter.length);
    }
    if (Disciplina) {
      console.log("Disciplina: ", Disciplina);
      inspeccionesFilter = inspeccionesFilter.filter(i => i.disciplina === Disciplina);
      console.log("Total inspecciones: ", inspeccionesFilter.length);
    }
    if (TipoInspeccion) {
      console.log("Tipo Inspección: ", TipoInspeccion);
      inspeccionesFilter = inspeccionesFilter.filter(i => i.tipo_inspeccion === TipoInspeccion)
      console.log("Total inspecciones: ", inspeccionesFilter.length);
    }
    if (Estado) {
      console.log("Estado: ", Estado);
      inspeccionesFilter = inspeccionesFilter.filter(i => i.estado === Estado)
      console.log("Total inspecciones: ", inspeccionesFilter.length);
    }
    if (Prioridad) {
      console.log("Prioridad: ", Prioridad);
      inspeccionesFilter = inspeccionesFilter.filter(i => i.prioridad === Prioridad)
      console.log("Total inspecciones: ", inspeccionesFilter.length);
    }
    if (FechaComprometidaCierreDesde) {
      console.log("Fecha Comprometida Cierre Desde: ", FechaComprometidaCierreDesde);
      inspeccionesFilter = inspeccionesFilter.filter(i => Moment(i.fecha_comprometida_cierre).isSameOrAfter(FechaComprometidaCierreDesde));
      console.log("Total inspecciones: ", inspeccionesFilter.length);
    }
    if (FechaComprometidaCierreHasta) {
      console.log("Fecha Comprometida Cierre Hasta: ", FechaComprometidaCierreHasta);
      inspeccionesFilter = inspeccionesFilter.filter(i => Moment(i.fecha_comprometida_cierre).isSameOrBefore(FechaComprometidaCierreHasta));
      console.log("Total inspecciones: ", inspeccionesFilter.length);
    }
    if (FechaRealCierreDesde) {
      console.log("Fecha Real Cierre Desde: ", FechaRealCierreDesde);
      inspeccionesFilter = inspeccionesFilter.filter(i => Moment(i.fecha_real_cierre).isSameOrAfter(FechaRealCierreDesde));
      console.log("Total inspecciones: ", inspeccionesFilter.length);
    }
    if (FechaRealCierreHasta) {
      console.log("Fecha Real Cierre Hasta: ", FechaRealCierreHasta);
      inspeccionesFilter = inspeccionesFilter.filter(i => Moment(i.fecha_real_cierre).isSameOrBefore(FechaRealCierreHasta));
      console.log("Total inspecciones: ", inspeccionesFilter.length);
    }
    SetInspeccionesFilter(inspeccionesFilter);
  }, [Proyecto, Area, Superintendencia, Disciplina, TipoInspeccion, Emisor, Contratista, Estado, Prioridad, FechaComprometidaCierreDesde, FechaComprometidaCierreHasta, FechaRealCierreDesde, FechaRealCierreHasta]);


  return (
    <Page
      is_loading={IsLoading}
      proyectos={Proyectos}
      proyecto={Proyecto}
      // set_proyecto={SetProyecto}
      areas={Areas}
      area={Area}
      set_area={SetArea}
      superintendencias={Superintendencias}
      superintendencia={Superintendencia}
      set_superintendencia={SetSuperintendencia}
      disciplinas={Disciplinas}
      disciplina={Disciplina}
      set_disciplina={SetDisciplina}
      tipos_inspeccion={TiposInspeccion}
      tipo_inspeccion={TipoInspeccion}
      set_tipo_inspeccion={SetTipoInspeccion}
      emisores={Emisores}
      emisor={Emisor}
      set_emisor={SetEmisor}
      contratistas={Contratistas}
      contratista={Contratista}
      set_contratista={SetContratista}
      estados={Estados}
      estado={Estado}
      set_estado={SetEstado}
      prioridades={Prioridades}
      prioridad={Prioridad}
      set_prioridad={SetPrioridad}
      fecha_comp_cierre_desde={FechaComprometidaCierreDesde}
      set_fecha_comp_cierre_desde={SetFechaComprometidaCierreDesde}
      fecha_comp_cierre_hasta={FechaComprometidaCierreHasta}
      set_fecha_comp_cierre_hasta={SetFechaComprometidaCierreHasta}
      fecha_real_cierre_desde={FechaRealCierreDesde}
      set_fecha_real_cierre_desde={SetFechaRealCierreDesde}
      fecha_real_cierre_hasta={FechaRealCierreHasta}
      set_fecha_real_cierre_hasta={SetFechaRealCierreHasta}
    />
  );
}

/**
 * Método encargado de obtener los proyetos de un contrato.
 */
function LoadProyectos() {
  let contratoID = localStorage.getItem("contrato");
  console.log("CONTRATO ID LOAD PROYECTOS", contratoID);
  let options = { method: "GET", headers: { connection: "keep-alive" } }
  return fetch("https://api-proyecto.cydocs.cl/contratos/" + contratoID + "/proyectos", options)
    .then(response => {
      if (response.status === 400) {
        throw new Error("Error 400 al intentar obtener proyectos.");
      }
      let data = response.json();
      console.log("RESPONSE PROYECTOS", response);
      console.log("DATA PROYECTOS", data);
      return data;
    })
    .then(proyectos => Array.from(proyectos.proyectos_ref))
    .then(data => data.map(d => ({ value: d._id, display: "[" + d.codigo + "]" + d.nombre })))
    .then(mapped => OrdenarDatos(mapped))
    .catch(error => {
      console.error(error);
      return [];
    });
}

/**
 * Método encargado de obtener todas las superintendencias de un contrato.
 */
function LoadSuperintendencias() {
  let contratoID = localStorage.getItem("contrato");
  console.log("CONTRATO ID LOAD SUPERINTENDENCIAS", contratoID);
  let options = { method: "GET", headers: { connection: "keep-alive" } }
  return fetch("https://api-proyecto.cydocs.cl/contratos/" + contratoID + "/superintendencias", options)
    .then(response => {
      if (response.status === 400) {
        throw new Error("Error 400 al intentar obtener superintendencias.");
      }
      let data = response.json();
      console.log("RESPONSE SUPERINTENDENCIAS", response);
      console.log("DATA SUPERINTENDENCIAS", data);
      return data;
    })
    .then(superintendencias => Array.from(superintendencias.superintendencias_ref))
    .then(data => data.map(d => ({ value: d.nombre, display: d.nombre })))
    .then(mapped => OrdenarDatos(mapped))
    .catch(error => {
      console.error(error);
      return [];
    });
}

/**
 * Método encargado de obtener todos los distintos emisores de inspecciones.
 * @param {*} inspecciones Colección de inspecciones.
 */
function LoadEmisores(inspecciones) {
  let mapped = Array.from(inspecciones).map(i => ({ value: i.emitido_por.email, display: i.emitido_por.displayName }));
  return OrdenarDatos(mapped);
}

/**
 * Método encargado de obtener todos los distintos estados de inspecciones.
 * @param {*} inspecciones Colección de inspecciones.
 */
function LoadEstados(inspecciones) {
  let mapped = Array.from(inspecciones).map(i => ({ value: i.estado, display: i.estado }));
  return OrdenarDatos(mapped);
}

function LoadAreas(proyectos) {

}

/**
 * Método encargado de obtener todos los distintos tipos de inspección.
 * @param {*} inspecciones Colección de inspecciones.
 */
function LoadTiposInspeccion(inspecciones) {
  let mapped = Array.from(inspecciones).map(i => ({ value: i.tipo_inspeccion, display: i.tipo_inspeccion }));
  return OrdenarDatos(mapped);
}

/**
 * Método encargado de obtener todos los distintos tipos de disciplinas.
 * @param {*} inspecciones Colección de inspecciones.
 */
function LoadDisciplinas(inspecciones) {
  let mapped = Array.from(inspecciones).map(i => ({ value: i.disciplina, display: i.disciplina }));
  return OrdenarDatos(mapped);
}

/**
 * Método encargado de obtener todas las distintas prioridades.
 * @param {*} inspecciones 
 */
function LoadPrioridades(inspecciones) {
  let mapped = Array.from(inspecciones).map(i => ({ value: i.prioridad, display: i.prioridad[0].toUpperCase() + i.prioridad.slice(1) }));
  return OrdenarDatos(mapped);
}

/**
 * Método encargado de identificar los datos únicos y ordenarlos.
 * @param {*} data Colección de datos.
 */
function OrdenarDatos(data) {
  let collection = Array.from(data).map(d => JSON.stringify(d));
  let dataSet = new Set(collection);
  let dataArray = Array.from(dataSet).map(e => JSON.parse(e));
  let dataSort = dataArray.sort((a, b) => {
    if (a.display < b.display) { return -1; }
    else if (a.display > b.display) { return 1; }
    else { return 0 };
  });
  return dataSort;
}

export default Index;