import { combineReducers } from 'redux';
import reducerDrawerMain from '../containers/drawerMain/reducer';
import reducerSelectorContrato from '../containers/selectorContrato/reducer';

import reducerSesion from './reducers/reducerSesion';
import reducerError from './reducers/reducerError';
import reducerLoading from './reducers/reducerLoading';
import reducerLogin from './reducers/reducerLogin';
import reducerLogout from './reducers/reducerLogout';
import reducerEjemplo from '../containers/empty/redux/reducerEjemplo';

const reducer = combineReducers({
    openSelectorContrato: reducerSelectorContrato,
    sesion: reducerSesion,
    error: reducerError,
    loading: reducerLoading,
    login: reducerLogin,
    logout: reducerLogout,
    openMenuLateral: reducerDrawerMain,
    storeEjemplo:reducerEjemplo
});

export default reducer;