import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';



const style = makeStyles(theme => ({
    toolbar: theme.mixins.toolbar,
    content: {
        flex: 1,
        padding: theme.spacing(3),
    },
    contenedor: {
        display: 'flex'
    },
    elemento: {
        width: '25%'
    },
    elemento10: {
        width: '10%'
    },
    padding16: {
        paddingLeft: '16',
        paddingRight: '16'
    },
    elementoContato: {
        width: '45%'
    },
    chip: {
        margin: theme.spacing(1),
    },
    main: {
        paddingLeft: theme.spacing(9),
        paddingTop: theme.spacing(11)
    }
}));

function Page(props) {
    const classes = style();
    return (
        <Container maxWidth="xl" className={classes.main} >
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                <Link color="inherit" href="/">
                    Log-Cartas
                </Link>
                <Typography color="textPrimary">Dashboard</Typography>
            </Breadcrumbs>
            {props.url &&
                <iframe title="dashboardDataStudio" src={props.url} frameBorder={0} style={{ border: 0, width: '100%', height: '80vh' }} allowFullScreen></iframe>
            }
        </Container >
    );
}

//"https://datastudio.google.com/embed/reporting/1Y0FxS91A-71qQhSsFkYzoY5HwZdSiFHI/page/weD6"

export default Page;