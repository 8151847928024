export const SET_CONTRATO = 'accionSetContratoSesion';
export const SET_PROYECTO = 'accionSetProyectoSesion';
export const SET_CONTRATO_PROYECTO = 'accionSetContratoProyectoSesion';
export const SET_USUARIO = 'accionSetUsuarioSesion';

export const setContrato = (contrato) => ({
    type: SET_CONTRATO,
    contrato: contrato
});

export const setProyecto = (proyecto) => ({
    type: SET_PROYECTO,
    proyecto: proyecto
});

export const setContratoProyecto = (contrato, proyecto) => ({
    type: SET_CONTRATO_PROYECTO,
    proyecto: proyecto,
    contrato: contrato
});

export const setUsuario = (usuario) => ({
    type: SET_USUARIO,
    usuario: usuario
});