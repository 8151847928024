import React from "react";
import Moment from "moment";
import Icon from '@mdi/react';
import { mdiCheck, mdiEmail, mdiFile, mdiFileHidden, mdiEmailAlert, mdiEmailSync } from '@mdi/js';
import Page from "./page";

function Index(props) {
  let {
    inspecciones_filter: InspeccionesFilter,
    is_loading: IsLoading,
  } = props;

  let tableHeaders = [
    { title: "Código", field: "correlativo", removable: false, render: (rowData) => <label style={{ color: rowData.esta_cerrada ? "black" : "white", fontWeight: rowData.esta_cerrada ? "normal" : "bold" }}>{rowData.correlativo}</label> },
    { title: "Proyecto", field: "proyecto", removable: false, render: (rowData) => <label style={{ color: rowData.esta_cerrada ? "black" : "white", width: "250px", fontWeight: rowData.esta_cerrada ? "normal" : "bold" }}>{rowData.proyecto}</label> },
    { title: "Superintendencia", field: "superintendencia", removable: false, render: (rowData) => <label style={{ color: rowData.esta_cerrada ? "black" : "white", fontWeight: rowData.esta_cerrada ? "normal" : "bold" }}>{rowData.superintendencia}</label> },
    { title: "Emisor", field: "emitido_por.displayName", removable: false, render: (rowData) => <label style={{ color: rowData.esta_cerrada ? "black" : "white", width: "150px", fontWeight: rowData.esta_cerrada ? "normal" : "bold" }}>{rowData.emitido_por.displayName}</label> },
    { title: "Título Inspección", field: "titulo_inspeccion", render: (rowData) => <label style={{ color: rowData.esta_cerrada ? "black" : "white", width: "150px", fontWeight: rowData.esta_cerrada ? "normal" : "bold" }}>{rowData.titulo_inspeccion}</label> },
    { title: "Tipo Inspección", field: "tipo_inspeccion", render: (rowData) => <label style={{ color: rowData.esta_cerrada ? "black" : "white", fontWeight: rowData.esta_cerrada ? "normal" : "bold" }}>{rowData.tipo_inspeccion}</label> },
    { title: "Disciplina", field: "disciplina", render: (rowData) => <label style={{ color: rowData.esta_cerrada ? "black" : "white", fontWeight: rowData.esta_cerrada ? "normal" : "bold" }}>{rowData.disciplina}</label> },
    { title: "Prioridad", field: "prioridad", render: (rowData) => <label style={{ color: rowData.esta_cerrada ? "black" : "white", fontWeight: rowData.esta_cerrada ? "normal" : "bold" }}>{rowData.prioridad}</label> },
    { title: "Fecha Creación", field: "creado_el", render: (rowData) => <label style={{ color: rowData.esta_cerrada ? "black" : "white", width: "120px", fontWeight: rowData.esta_cerrada ? "normal" : "bold" }}>{Moment(rowData.creado_el).format("DD/MM/YYYY HH:mm")}</label> },
    { title: "Fecha Cierre", field: "fecha_real_cierre", render: (rowData) => <label style={{ color: rowData.esta_cerrada ? "black" : "white", width: "120px", fontWeight: rowData.esta_cerrada ? "normal" : "bold" }}>{Moment(rowData.fecha_real_cierre).format("DD/MM/YYYY HH:mm")}</label> },
    { title: "¿Cerrada?", field: "esta_cerrada", render: (rowData) => rowData.esta_cerrada ? <div><Icon path={mdiCheck} size={1} className="ml-4" /></div> : "" },
    { title: "Email Emisor", field: "emitido_por.email", hidden: true, render: (rowData) => <label style={{ color: rowData.esta_cerrada ? "black" : "white", fontWeight: rowData.esta_cerrada ? "normal" : "bold" }}>{rowData.emitido_por.email}</label> },
    { title: "Estado", field: "estado", hidden: true, render: (rowData) => <label style={{ color: rowData.esta_cerrada ? "black" : "white", fontWeight: rowData.esta_cerrada ? "normal" : "bold" }}>{rowData.estado}</label> },
    { title: "Fecha Comp. Cierre", field: "fecha_comprometida_cierre", render: (rowData) => <label style={{ color: rowData.esta_cerrada ? "black" : "white", width: "120px", fontWeight: rowData.esta_cerrada ? "normal" : "bold" }}>{Moment(rowData.fecha_comprometida_cierre).format("DD/MM/YYYY HH:mm")}</label> },
    { title: "Observación", field: "obsevacion", hidden: true, render: (rowData) => <label style={{ color: rowData.esta_cerrada ? "black" : "white", width: "450px", fontWeight: rowData.esta_cerrada ? "normal" : "bold" }}>{rowData.obsevacion}</label> },
    { title: "Observación de Cierre", field: "obsevacion_cierre", hidden: true, render: (rowData) => <label style={{ color: rowData.esta_cerrada ? "black" : "white", width: "350px", fontWeight: rowData.esta_cerrada ? "normal" : "bold" }}>{rowData.obsevacion_cierre}</label> },
  ];

  let actions = [
    rowData => ({ icon: () => <Icon path={rowData.id_pdf ? mdiFile : mdiFileHidden} size={1.25} color={rowData.esta_cerrada ? "gray" : "white"} />, tooltip: rowData.id_pdf ? "Informe" : "Sin Informe", onClick: (e, rowData) => RedirectToPDF(rowData.id_pdf), disabled: rowData.id_pdf ? false : true }),
    rowData => ({ icon: () => <Icon path={rowData.id_pdf_cierre ? mdiFile : mdiFileHidden} size={1.25} color={rowData.esta_cerrada ? "gray" : "white"} />, tooltip: rowData.id_pdf_cierre ? "Informe Cierre" : "Sin Informe Cierre", onClick: (e, rowData) => RedirectToPDF(rowData.id_pdf_cierre), disabled: rowData.id_pdf_cierre ? false : true }),
    rowData => ({ icon: () => <Icon path={rowData.send_mail ? mdiEmail : mdiEmailSync} size={1.25} color={rowData.esta_cerrada ? "gray" : "white"} />, tooltip: rowData.send_mail ? "Email Enviados" : "Sin Email Enviados", onClick: (e, rowData) => alert("Email!!!!"), disabled: rowData.send_mail ? false : true }),
  ]

  return (
    <Page inspecciones_filter={InspeccionesFilter} table_headers={tableHeaders} actions={actions} is_loading={IsLoading} />
  );
}

/**
 * Método encargado de abrir un informe PDF en una nueva pestaña del navegador.
 * @param {*} pdf_id Identificador único del PDF en Google Drive.
 */
function RedirectToPDF(pdf_id) {
  window.open("https://drive.google.com/file/d/" + pdf_id + "/view");
}

export default Index;