import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Page from "./page"
import Dashboard from "./dashboard"
import Modal from "./modal"
import moment from 'moment';
import * as PERMISSIONS from './../../constants/permissions';
import 'moment/locale/es';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';
import { IconButton,Container,Breadcrumbs,Link ,Typography, Button} from '@material-ui/core';
import {addidInspecciones} from  '../../services/database/log_inspecciones'

import {
    Close as CloseIcon,
    NavigateNext as NavigateNextIcon  
} from '@material-ui/icons';

import { accionOpenContenido } from './redux/actionsEjemplo';

import { getInspecciones } from '../../services/database/log_inspecciones';
moment.locale('es');

const style = makeStyles(theme => ({

    main: {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(12) ,

    }
}))

export default function Cartas(props) {
    const { proyecto, contrato,sesion } = useSelector(state => ({
        contrato: state.sesion.contrato,
        proyecto: state.sesion.proyecto,
        sesion: state.sesion,
    }));

    const {
        permiso
    }=props;

    const history = useHistory();
    const notisnack = useSnackbar();
    const dispatch = useDispatch();



    const goTo = (path) => {
        history.push(path);
    }
    const classes = style();

    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [totalCierre, setTotalCierre] = useState(0);
    const [totalPendienteCierre, setTotalPendienteCierre] = useState(0);

    const loadData = async () => {
        if (proyecto) {

            let data = await getInspecciones(proyecto._id);
            let totalData = data.length;
            let totalCierreData = await data.filter((item)=>{
                
                if(item.esta_cerrada){
                    return item;
                }
                
            });
            let totalPendienteCierreData = await data.filter((item)=>{
                
                if(!item.esta_cerrada){
                    return item;
                }
                
            });
            

            setTotal(totalData);
            setTotalCierre(totalCierreData.length);
            setTotalPendienteCierre(totalPendienteCierreData.length)
            
            setData(data.reverse());
        }
    };


    const viewPDF = (data) => {
        var url = "https://drive.google.com/file/d/" + data.id_pdf + "/view";
        window.open(url, "_blank") //to open new page
        
    }

    const viewPDFCierre = (data) => {
        var url = "https://drive.google.com/file/d/" + data.id_pdf_cierre + "/view";
        window.open(url, "_blank") //to open new page
        
    }



    const handleOnClick = async (texto) => {
        // const key = notisnack.enqueueSnackbar(texto, {
        //     persist: true,
        //     anchorOrigin: {
        //         horizontal: "center",
        //         vertical: "bottom"
        //     }
        // });
        const successkey = notisnack.enqueueSnackbar("Error: No ha sido posible crear la carta. Intente de nuevo o contacte con soporte", {
            variant: 'error',
            persist: true,
            anchorOrigin: {
                horizontal: "right",
                vertical: "top"
            },
            action: <IconButton onClick={() => notisnack.closeSnackbar(successkey)}><CloseIcon /></IconButton>
        });
        await addidInspecciones();
        dispatch(accionOpenContenido("data"));

    }
    useEffect(() => {
        
        loadData();
    }, [proyecto]);


    console.log(sesion?.usuario?.permisos[PERMISSIONS.VER_ENVIO_INSPECCION]);
    
    return (
        <Container maxWidth="xl" className={classes.main}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                <Link color="inherit" href="/contrato">
                    Apps MLP
                            </Link>
                {/* <Link color="inherit" href="/getting-started/installation/" >
                            Contratos Vigentes
                            </Link> */}
                <Typography color="textPrimary">Log Inspecciones </Typography>
            </Breadcrumbs>
            {/* <Button onClick={handleOnClick} style={{Hidden=true}}>asdas</Button> */}
            <Dashboard data={data} 
                total={total} 
                totalCierre={totalCierre} 
                totalPendienteCierre={totalPendienteCierre}/>
            <Page
                data={data}
                onViewerPDF={viewPDF}
                onViewerPDFCierre={viewPDFCierre}
                onRefreshData={loadData}
                permiso={sesion?.usuario?.permisos[PERMISSIONS.VER_ENVIO_INSPECCION]}
            />
            <Modal ></Modal>
        </Container>
    )
}