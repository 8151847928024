import { SET_CONTRATO, SET_PROYECTO, SET_CONTRATO_PROYECTO, SET_USUARIO } from '../actions/actionsSesion';


const defaultState = [{
    contrato: null,
    proyecto: null,
    usuario: null
}];

export default function reducerSesion(state = defaultState, { type, proyecto, contrato, usuario }) {
    switch (type) {
        case SET_CONTRATO: {
            if (!contrato) {
                return state;
            }
            return {...state,  contrato: contrato};
        }
        case SET_PROYECTO: {
            if (!proyecto) {
                return state;
            }
            return {...state,  proyecto: proyecto};
        
        }
        case SET_CONTRATO_PROYECTO: {
            if (!contrato || !proyecto) {
                return state;
            }
            return {...state,  contrato: contrato, proyecto: proyecto};
        }
        case SET_USUARIO:{
            if (!usuario) {
                return state;
            }
            return {...state,  usuario: usuario};
        }
        default:
            return state;
    }
}