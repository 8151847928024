import { OPEN_MENU_LATERAL, CLOSE_MENU_LATERAL } from './actions';


const defaultState = false;

export default function reducerMenuLateral(state = defaultState, { type }) {
    switch (type) {
        case OPEN_MENU_LATERAL:
            return true;
        case CLOSE_MENU_LATERAL:
            return false;
        default:
            return state;
    }
}