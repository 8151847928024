import React, { useState, useEffect } from "react";
import Page from "./page";
import { useSelector } from "react-redux";

function Index(props) {
  const [Inspecciones, SetInspecciones] = useState([]);
  const [InspeccionesFilter, SetInspeccionesFilter] = useState([]);
  const [IsLoading, SetIsLoading] = useState(true);

  const { contrato, proyecto } = useSelector(state => ({
    contrato: state.sesion.contrato,
    proyecto: state.sesion.proyecto,
  }));

  useEffect(() => {
    let contratoID = localStorage.getItem("contrato");
    let proyectoID = localStorage.getItem("proyecto");
    let options = { method: "GET", headers: { connection: "keep-alive" } }
    fetch('https://api-usuarios.cydocs.cl/logs/' + contratoID + '/' + proyectoID, options)
      .then(response => response.json())
      .then(inspeccionesAPI => SetInspecciones(inspeccionesAPI))
      .catch(error => {
        console.error(error);
        SetInspecciones([]);
      });
  }, [contrato, proyecto]);

  useEffect(() => {
    let contratoID = localStorage.getItem("contrato");
    let proyectoID = localStorage.getItem("proyecto");
    if (Inspecciones && Inspecciones.length > 0) {
      SetInspeccionesFilter(Inspecciones);
      SetIsLoading(false);
    }
  }, [Inspecciones]);

  return (
    <Page
      inspecciones={Inspecciones}
      inspecciones_filter={InspeccionesFilter}
      set_inspecciones_filter={SetInspeccionesFilter}
      is_loading={IsLoading}
    />
  );
}

export default Index;