import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { red, green, yellow } from "@material-ui/core/colors/";
import Moment from "moment";

function MaterialTableComponent({ data: Data, is_loading: IsLoading, title: Title, columns: Columns, actions: Actions, components: Components, }) {
  const [PageSize, SetPageSize] = useState(10);
  const [PageSizes, SetPageSizes] = useState([]);

  useEffect(() => {
    let pageSizes = [10];
    if (Data && Data.length > 0) {
      pageSizes.push(Math.ceil(Data.length / 10) * 1);
      pageSizes.push(Math.ceil(Data.length / 10) * 2);
      pageSizes.push(Math.ceil(Data.length / 10) * 5);
      pageSizes.push(Math.ceil(Data.length / 10) * 10);
    }
    SetPageSizes(pageSizes);
  }, [Data]);

  useEffect(() => {
    SetPageSize(PageSizes[0]);
  }, [PageSizes]);

  let options = {
    pageSize: PageSize,
    pageSizeOptions: PageSizes,
    emptyRowsWhenPaging: false,
    columnsButton: true,
    exportButton: true,
    padding: "dense",
    rowStyle: rowData => ({ backgroundColor: rowData.esta_cerrada ? "white" : Moment().isSameOrBefore(Moment(rowData.fecha_comprometida_cierre).subtract(5, "days")) ? green[500] : Moment().isSameOrBefore(Moment(rowData.fecha_comprometida_cierre).subtract(2, "days")) ? yellow[800] : red[500] }),
  }

  let localization = {
    toolbar: { searchPlaceholder: "Buscar", searchTooltip: "Buscar" },
    header: { actions: "Opciones" },
    body: { emptyDataSourceMessage: IsLoading ? "Cargando" : "" },
    pagination: { labelRowsSelect: "Filas" }
  }

  let localComponents = {}

  return (
    <MaterialTable
      options={options}
      localization={localization}

      title={Title}
      isLoading={IsLoading}
      columns={Columns}
      actions={Actions}
      data={Data}
      components={Object.assign(localComponents, Components)}
    />
  );
}

export default MaterialTableComponent;